
















































































import { Nullable } from '@/types'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const CitiesStore = namespace('CitiesStore')
import { CityModel } from '@/core/models/CityModel'
import Overlay from '@/components/Overlay.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import AddAddressForm from './AddAddressForm.vue'
import { LocationModel, MetroStation } from '@/core/models/LocationModel'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import { setItem, getItem } from '@/utils/persistanceStorage'
import { serialize } from 'object-to-formdata'

import axios from '@/api/axios'

import breakpoints from '@/plugins/breakpoints'

interface yMapSettings {
  coords: number[]
  zoom: number
}

interface RouteCache {
  [key: string]: Nullable<number>
}

type Point = string | [number, number]

@Component({
  name: 'AddressForm',
  components: {
    Overlay,
    BaseIcon,
    BaseButton,
    AddAddressForm,
    yandexMap,
    ymapMarker,
  },
})
export default class AddressForm extends Vue {
  model: LocationModel = {
    id: null,
    name: '',
    address: '',
    addressLegacy: '',
    phone: '',
    whatsapp: '',
    telegram: '',
    active: true,
    parkingInfo: '',
    wayDirections: '',
    cityId: null,
    areaId: null,
    gpsCoords: {
      lat: 0,
      lng: 0,
    },
    metroStations: [],
    addressData: {},
  }

  error = ''
  saveDisabled = false

  markerIcon = {
    layout: 'default#imageWithContent',
    imageHref: '/img/map-icon.svg',
    imageSize: [38, 38],
  }

  defaultCoordinates = [55.755819, 37.617644]

  yMapSettings: yMapSettings = {
    coords: this.defaultCoordinates,
    zoom: 17,
  }

  cache: RouteCache = getItem('routeCache') || {}

  breakpoints = breakpoints

  @CitiesStore.Getter
  public cityList!: CityModel[]

  get formTitleText(): string {
    return this.model.id !== null
      ? 'Редактиро&shy;ва&shy;ние адреса'
      : 'Новый адрес'
  }

  get isMobile(): boolean {
    return breakpoints.width <= 768
  }

  get mapBehaviors(): string[] {
    if (this.isMobile) {
      return []
    }
    return ['drag', 'scrollZoom', 'dblClickZoom', 'multiTouch']
  }

  get isAvailableMapClick(): boolean {
    const isDefaultCoordinates =
      this.yMapSettings.coords.filter((coordinate) =>
        this.defaultCoordinates.includes(coordinate)
      ).length === 2 ||
      this.yMapSettings.coords.filter((coordinate) =>
        [0, 0].includes(coordinate)
      ).length === 2

    if (isDefaultCoordinates) {
      return true
    }
    return false
  }

  public async getRouteTime(
    pointA: Point,
    pointB: Point
  ): Promise<Nullable<number>> {
    try {
      const multiRoute = await window.ymaps.route([pointA, pointB], {
        multiRoute: true,
        routingMode: 'pedestrian',
      })
      const route = multiRoute.getActiveRoute()
      if (!route) return null
      const duration = route.properties.get('duration')
      if (!duration) return null
      return duration.value ? Math.round(duration.value / 60) : null
    } catch (error) {
      console.error(error)
      return null
    }
  }

  public setModel(model: LocationModel): void {
    this.model = model
    if (!(model.gpsCoords.lat === 0 && model.gpsCoords.lng === 0)) {
      this.yMapSettings.coords = [model.gpsCoords.lat, model.gpsCoords.lng]
    }
  }

  public resetModel(): void {
    this.model = {
      id: null,
      name: '',
      address: '',
      addressLegacy: '',
      phone: '',
      whatsapp: '',
      telegram: '',
      active: true,
      parkingInfo: '',
      wayDirections: '',
      cityId: null,
      areaId: null,
      gpsCoords: {
        lat: 0,
        lng: 0,
      },
      metroStations: [],
      addressData: {},
    }
  }

  public async storeAddress(): Promise<void> {
    const uri =
      this.model.id === null
        ? '/api/b2b/v1/locations'
        : '/api/b2b/v1/locations/' + this.model.id

    try {
      await axios.post(
        uri,
        serialize(
          {
            id: this.model.id,
            active: this.model.active,
            name: this.model.name,
            parking_info: this.model.parkingInfo,
            way_directions: this.model.wayDirections,
            address: this.model.addressData,
            phone: this.model.phone,
            whatsapp: this.model.whatsapp,
            telegram: this.model.telegram,
            gps_coords: {
              lat: this.yMapSettings.coords[0],
              lng: this.yMapSettings.coords[1],
            },
            city_id: this.model.cityId,
            area_id: this.model.areaId,
            metro_stations: this.model.metroStations.map((item) => ({
              id: item.id,
              main: item.main,
              time: item.time,
            })),
          },
          {
            indices: true,
            booleansAsIntegers: true,
            nullsAsUndefineds: true,
          }
        )
      )

      this.$emit('close')
      this.$emit('addressStored')
    } catch (e) {
      let errorText = ''
      let errorMessage
      for (errorMessage in e.response.data.errors) {
        if (typeof e.response.data.errors[errorMessage] === 'string') {
          errorText += e.response.data.errors[errorMessage] + '\n'
        } else {
          errorText += e.response.data.errors[errorMessage][0] + '\n'
        }
      }

      this.error = errorText
      console.log(e)
    }
  }

  public async submit(): Promise<void> {
    this.error = ''

    if ((this.$refs.form as any).checkValidity()) {
      this.saveDisabled = true
      await this.storeAddress()
      this.saveDisabled = false
    }
  }

  public onChangeAddAddressForm(addAddressFormData: LocationModel): void {
    this.setModel(addAddressFormData)
    this.updateMetroStation()
  }

  public clickToMap(event: any, currentCoordinates: number[] = []): void {
    let newCoordinates: number[] = []

    if (this.isAvailableMapClick) {
      newCoordinates = event.get('coords')
    } else {
      newCoordinates = currentCoordinates.length
        ? currentCoordinates
        : this.yMapSettings.coords
    }

    this.yMapSettings = {
      ...this.yMapSettings,
      coords: newCoordinates,
    }

    this.model.gpsCoords.lat = this.yMapSettings.coords[0]
    this.model.gpsCoords.lng = this.yMapSettings.coords[1]

    this.updateMetroStation()
  }

  public stationToString(value: MetroStation): string {
    const city = this.cityList?.find((item) => item.id === value.city)?.name
    const station = `метро ${value.name}`
    return city ? `${city}, ${station}` : station
  }

  public async calculateStation(value: MetroStation): Promise<void> {
    const coords = this.yMapSettings.coords as Point
    const key = `${value.name}_${this.yMapSettings.coords[0]}_${this.yMapSettings.coords[1]}`
    let result = this.cache[key]
    if (result === undefined) {
      result = await this.getRouteTime(this.stationToString(value), coords)
      // защита от переполнения
      if (Object.keys(this.cache).length > 10000) this.cache = {}
      this.cache[key] = result
      setItem('routeCache', this.cache)
    }
    const current = this.model.metroStations.find(
      (item) => item.id === value.id
    )
    if (
      current &&
      this.yMapSettings.coords[0] === coords[0] &&
      this.yMapSettings.coords[1] === coords[1]
    ) {
      current.time = result
    }
  }

  public updateMetroStation(): void {
    this.model.metroStations.forEach((item) => {
      this.calculateStation(item)
    })
  }

  public onDragEndMarker(event: any): void {
    const currentCoordinates = event.get('target').geometry.getCoordinates()
    this.clickToMap(null, currentCoordinates)
  }
}
